import React from 'react'
import { Route, Routes } from 'react-router'
import StoreAccounting from './pages/StoreAccounting/StoreAccounting'
import BS from './pages/BS/BS'
import { CountryCode } from './models/countryCode'
import FormNew from './pages/StoreAccounting/forms/new/FormNew'
import Success from './pages/StoreAccounting/success/Success'
import FormExisting from './pages/StoreAccounting/forms/existing/FormExisting'

const App = () => {
  const countryCode = new URLSearchParams(window.location.search)
    .get('country')
    ?.toUpperCase()
  const translations = new URLSearchParams(window.location.search).get(
    'translations',
  )

  sessionStorage.setItem(
    'country',
    countryCode || sessionStorage.getItem('country') || CountryCode.SE,
  )
  sessionStorage.setItem('translations', translations || '')

  return (
    <React.StrictMode>
      <Routes>
        <Route path="/*" element={<BS />} />
        <Route path="butiksredovisning" element={<StoreAccounting />} />
        <Route path="butiksredovisning/new-user" element={<FormNew />} />
        <Route
          path="butiksredovisning/existing-user"
          element={<FormExisting />}
        />
        <Route path="butiksredovisning/success" element={<Success />} />
      </Routes>
    </React.StrictMode>
  )
}
export default App
