import React from 'react'
import CountryPicker from '../../components/CountryPicker'
import styles from './languageBar.module.scss'

const LanguageBar = () => (
  <div className={styles.languageBar}>
    <CountryPicker />
  </div>
)

export default LanguageBar
