import React from 'react'
import { getText } from '../../utils/languageHandler'
import styles from './formInfo.module.scss'

interface Props {
  headline: string
  info: string
}

const FormInfo = (props: Props) => (
  <div className={styles.formInfo}>
    <h3 className={styles.header}>{getText(props.headline)}</h3>
    <div className={styles.body}>{getText(props.info)}</div>
  </div>
)

export default FormInfo
