import { FormFieldFormat, FormFieldKind } from '@resurs/styx-react/'
import { getText } from './languageHandler'
import { FormFieldValue } from '../interfaces/FormFieldValue'
import { FormFieldValid } from '../interfaces/FormFieldValid'

export enum InputTypes {
  OldFinanceUsername = 'oldFinanceUsername',
  OldFinancePassword = 'oldFinancePassword',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Username = 'username',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

export const defaultFormValueState: FormFieldValue = {
  value: '',
}

export const defaultFormFieldValid: FormFieldValid = {
  isValid: false,
  kind: FormFieldKind.Default,
}

export const emailFormat = (): FormFieldFormat => ({
  regex:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  error: getText('sa.form.validation.email'),
})

export const passwordFormat = (): FormFieldFormat => ({
  regex: '^[^\\s]{4,}$',
  error: getText('sa.form.validation.password'),
})

export const onFocusElement = <
  T extends { [x: string]: FormFieldValid },
  K extends keyof T,
>(
  validState: T,
  input: K,
  callback: (
    entries:
      | { [x: string]: { isValid: boolean; kind: FormFieldKind; help: null } }[]
      | undefined,
  ) => void,
): void => {
  let entries
  if (validState[input].groupValidation) {
    entries = Object.entries(validState)
      .filter((el) => el[1]?.groupValidation)
      .map((el) => ({
        [el[0]]: {
          isValid: true,
          kind: FormFieldKind.Approved,
          help: null,
        },
      }))
  } else if (validState[input].kind === FormFieldKind.Error) {
    entries = [
      {
        [input]: {
          isValid: false,
          kind: FormFieldKind.Default,
          help: null,
        },
      },
    ]
  }
  callback(entries)
}

export const onValidChangeElement = <
  T extends { [x: string]: FormFieldValue },
  K extends keyof T,
>(
  valueState: T,
  input: K,
  isValid: boolean,
  callback: (entry: { [x: string]: FormFieldValid }) => void,
): void => {
  if (valueState[input].value) {
    const entry = {
      [input]: {
        isValid,
        kind: isValid ? FormFieldKind.Approved : FormFieldKind.Default,
      },
    }
    callback(entry)
  }
}
