import { FormFieldKind } from '@resurs/styx-react/'
import { getText } from './languageHandler'
import { FormFieldValid } from '../interfaces/FormFieldValid'
import { apiErrorToTranslationKeyMapper } from './apiErrorToTranslationKeyMapper'
import { ApiError } from '../interfaces/ApiError'
import { InputTypes } from './formUtils'

export const handleFormApiErrors = (
  error: ApiError | undefined,
  callback: (entry: { [x: string]: FormFieldValid }) => void,
): void => {
  let finance401 = {}
  if (
    error?.status === 401 &&
    apiErrorToTranslationKeyMapper(error?.message || '') ===
      'sa.api_error.401.unknown_finance_user'
  ) {
    finance401 = {
      [InputTypes.OldFinanceUsername]: {
        isValid: false,
        kind: FormFieldKind.Error,
        groupValidation: true,
      },
      [InputTypes.OldFinancePassword]: {
        isValid: false,
        kind: FormFieldKind.Error,
        help: getText('sa.api_error.401.unknown_finance_user'),
        groupValidation: true,
      },
    }
  }

  let merchant401 = {}
  if (
    error?.status === 401 &&
    apiErrorToTranslationKeyMapper(error?.message || '') ===
      'sa.api_error.401.unknown_merchant_user'
  ) {
    merchant401 = {
      [InputTypes.Username]: {
        isValid: false,
        kind: FormFieldKind.Error,
        groupValidation: true,
      },
      [InputTypes.Password]: {
        isValid: false,
        kind: FormFieldKind.Error,
        help: getText('sa.api_error.401.unknown_merchant_user'),
        groupValidation: true,
      },
    }
  }

  let fields
  if (error?.validationErrors) {
    fields = error.validationErrors.map((err) => {
      const input = err.fieldName as InputTypes
      return {
        [input as string]: {
          isValid: false,
          kind: FormFieldKind.Error,
          help: getText(apiErrorToTranslationKeyMapper(err.message)),
        },
      }
    })
  }
  callback({
    ...Object.assign({}, ...(fields || [])),
    ...merchant401,
    ...finance401,
  })
}
