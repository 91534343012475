import { LanguageFiles } from '../models/languageFiles'
import { CountryCode } from '../models/countryCode'

export const getCountryCode = () =>
  sessionStorage.getItem('country') || CountryCode.SE

export const getText = (textKey: string): string => {
  if (sessionStorage.getItem('translations') === 'keys') {
    return textKey
  }

  return LanguageFiles[getCountryCode()][textKey] || ''
}
