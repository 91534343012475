import React from 'react'
import { getText } from '../../utils/languageHandler'
import styles from './headline.module.scss'

interface Props {
  headline: string
  lead: string
}

const Headline = (props: Props) => (
  <div className={styles.headline}>
    <h1 className={styles.h1}>{getText(props.headline)}</h1>
    <p>{getText(props.lead)}</p>
  </div>
)

export default Headline
