import React from 'react'
import { getText } from '../../utils/languageHandler'
import styles from './customerServiceInfo.module.scss'

const CustomerServiceInfo = () => {
  const contactCustomerService = getText('sa.info').split('{}')
  const phone = getText('sa.info.phone')
  const email = getText('sa.info.email')

  return (
    <p className={styles.customerServiceInfoText}>
      <span>{contactCustomerService[0]}</span>
      <a href={`tel:${phone}`}>{phone}</a>
      <span>{contactCustomerService[1]}</span>
      <a href={`mailto:${email}`}>{email}</a>
    </p>
  )
}
export default CustomerServiceInfo
