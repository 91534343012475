import config from '../config'
import { post } from './api-utils'

export interface CreateMerchantPortalAccountRequest {
  oldFinanceUsername: string
  oldFinancePassword: string
  username: string
  firstName: string
  lastName: string
  email: string
  languageCode: string
  password: string
}

export interface UseExistingMerchantPortalAccountRequest {
  oldFinanceUsername: string
  oldFinancePassword: string
  username: string
  password: string
  languageCode: string
}

class UserServiceApi {
  static useExistingMerchantPortalAccount = (
    requestData: UseExistingMerchantPortalAccountRequest,
  ) =>
    post(
      `${config.userServiceUrl}/migration/users/transfer_stores`,
      requestData,
    )

  static createMerchantPortalAccount = (
    requestData: CreateMerchantPortalAccountRequest,
  ) =>
    post(`${config.userServiceUrl}/migration/users/create_finance`, requestData)
}

export default UserServiceApi
