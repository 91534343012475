import React from 'react'
import { getText } from '../../utils/languageHandler'
import styles from './footer.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.wave} />
    <div className={styles.background}>
      <div className={styles.logotype} />
      <div className={styles.textBox}>
        <div>{getText('footer.1')}</div>
        <div>{getText('footer.2')}</div>
        <div>{getText('footer.3')}</div>
      </div>
    </div>
  </div>
)

export default Footer
