import React from 'react'
import styles from './countryPicker.module.scss'
import { CountryCode } from '../../models/countryCode'

const flagStyles = {
  [CountryCode.SE]: styles.seFlag,
  [CountryCode.NO]: styles.noFlag,
  [CountryCode.FI]: styles.fiFlag,
  [CountryCode.DK]: styles.dkFlag,
}

const anchors = () =>
  Object.values(CountryCode).map((country) => (
    <a
      key={country.toLowerCase()}
      href={`?country=${country.toLowerCase()}`}
      className={flagStyles[country]}
    >
      &nbsp;
    </a>
  ))

const CountryPicker = () => (
  <div className={styles.countryPicker}>{anchors()}</div>
)

export default CountryPicker
