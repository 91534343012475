import React from 'react'
import styles from './header.module.scss'

const Header = () => (
  <div className={styles.header}>
    <div id="logo" className={styles.logotype} />
  </div>
)

export default Header
