/* eslint-disable  @typescript-eslint/no-explicit-any */
export const apiErrorToTranslationKeyMapper = (message: string) => {
  switch (message) {
    case 'Unauthorized':
      return 'sa.api_error.401.unknown_merchant_user'
    case 'Could not validate credentials for old accounting user':
      return 'sa.api_error.401.unknown_finance_user'
    case 'Email is not unique':
      return 'sa.api_error.409.email'
    default:
      // eslint-disable-next-line no-console
      console.error(`Could not map message to key for message: ${message}`)
      return message
  }
}
