import React from 'react'
import Page from '../../components/Page'
import Headline from '../../components/Headline'
import Card from '../../components/Card'
import CustomerServiceInfo from '../../components/CustomerServiceInfo'
import styles from './storeAccounting.module.scss'

const StoreAccounting = () => (
  <Page>
    <div className={styles.storeAccounting}>
      <Headline headline="sa.info.headline" lead="sa.info.lead" />
      <Card
        id="buttonNewUser"
        cardHeadline="sa.info.card_headline_new"
        cardInfo="sa.info.card_info_new"
        buttonText="sa.info.card_button_new"
        href="new-user"
      />

      <Card
        id="buttonExistingUser"
        cardHeadline="sa.info.card_headline_existing"
        cardInfo="sa.info.card_info_existing"
        buttonText="sa.info.card_button_existing"
        href="existing-user"
        transparent
        outline
      />
      <div className={styles.customerService}>
        <CustomerServiceInfo />
      </div>
    </div>
  </Page>
)

export default StoreAccounting
