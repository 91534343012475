import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing, ErrorBoundary } from '@sentry/react'
import App from './App'
import './styles/styx.scss'
import config from './config'

if (
  config.sentryEnabled &&
  Array.of('dev', 'integration', 'production').includes(
    config.sentryEnvironment || '',
  )
) {
  const SENTRY_RELEASE_VERSION = process.env.REACT_APP_GIT_COMMIT
  Sentry.init({
    dsn: 'https://c1eb1d62bb27a161d4ff8769e2064c03@o566388.ingest.sentry.io/4506195651526656',
    integrations: [new BrowserTracing()],
    normalizeDepth: 10,
    environment: config.sentryEnvironment,
    ignoreErrors: [],
    release: `bs-landing-page@${SENTRY_RELEASE_VERSION}`,
  })
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
)
