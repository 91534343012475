import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonType } from '@resurs/styx-react'
import { getText } from '../../utils/languageHandler'
import styles from './card.module.scss'
import { queryParamsUtils } from '../../utils/queryParamsUtils'

interface Props {
  id: string
  cardHeadline?: string
  cardInfo: string
  buttonText: string
  href: string
  redirect?: boolean
  transparent?: boolean
  outline?: boolean
}

const Card = ({
  buttonText,
  cardHeadline,
  cardInfo,
  href,
  id,
  outline,
  redirect,
  transparent,
}: Props) => {
  const mainClass = transparent ? styles.cardTransparent : styles.cardPlain
  const buttonType = ButtonType.Primary

  const getButton = (): ReactNode => (
    <Button
      id={id}
      type={buttonType}
      fullWidth
      outline={!!outline}
      label={getText(buttonText)}
    />
  )

  return (
    <div className={styles.card}>
      <div className={mainClass}>
        {cardHeadline && (
          <h4 className={styles.cardHeader}>{getText(cardHeadline)}</h4>
        )}
        <div className={styles.cardBody}>{getText(cardInfo)}</div>
        <div className={styles.cardButton}>
          {redirect ? (
            <a href={href} className="button">
              {getButton()}
            </a>
          ) : (
            <Link
              to={{
                pathname: href,
                search: queryParamsUtils(),
              }}
              className="button"
            >
              {getButton()}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
