import React from 'react'
import Lottie from 'react-lottie-player'
import Page from '../../../components/Page'
import Card from '../../../components/Card'
import { getText } from '../../../utils/languageHandler'
import CustomerServiceInfo from '../../../components/CustomerServiceInfo'
import pos from '../../../assets/lottie/confirm_positiv.json'
import config from '../../../config'
import styles from './success.module.scss'

const Success = () => {
  const isNewUser = sessionStorage.getItem('flow') === 'new'

  return (
    <Page>
      <div className={styles.successPage}>
        <div>
          <Lottie
            className={styles.lottie}
            loop={false}
            animationData={pos}
            play
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
          />
        </div>

        <h3 className={styles.h3}>{getText('sa.success.heading')}</h3>
        {isNewUser ? (
          <>
            <p className={styles.p}>{getText('sa.success.new_user.info')}</p>
            <Card
              id="success"
              cardInfo="sa.success.card.info"
              buttonText="sa.success.card.button"
              href={config.merchantPortalUrl}
              redirect
            />
          </>
        ) : (
          <Card
            id="success"
            cardInfo="sa.success.info"
            buttonText="sa.success.card.button"
            href={config.merchantPortalUrl}
            redirect
            transparent
          />
        )}

        <div className={styles.customerService}>
          <CustomerServiceInfo />
        </div>
      </div>
    </Page>
  )
}

export default Success
