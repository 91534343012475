import React from 'react'
import { FormFieldFormat, TextInput, TextInputType } from '@resurs/styx-react'
import { getText } from '../../utils/languageHandler'
import { FormFieldValue } from '../../interfaces/FormFieldValue'
import { FormFieldValid } from '../../interfaces/FormFieldValid'

interface Props {
  input: string
  label: string
  format?: () => FormFieldFormat
  type?: TextInputType
  valueState: { [x: string]: FormFieldValue }
  validState: { [x: string]: FormFieldValid }
  onChange: (id: string, value: string) => void
  onValidChange: (id: string, valid: boolean) => void
  onFocus: (id: string) => void
  required?: string
}

const TextInputHelper = ({
  format,
  input,
  label,
  type,
  valueState,
  validState,
  onChange,
  onValidChange,
  onFocus,
  required,
}: Props) => (
  <TextInput
    id={input}
    label={getText(label)}
    value={valueState[input].value}
    onChange={onChange}
    onValidChange={onValidChange}
    kind={validState[input].kind}
    required={
      (required && getText(required)) ||
      `${getText(label)} ${getText('sa.form.validation.required')}`
    }
    onFocus={onFocus}
    help={validState[input].help}
    type={type || TextInputType.Text}
    format={format ? format() : undefined}
  />
)

export default TextInputHelper
