import { captureMessage, setExtra } from '@sentry/react'

export const setupJsonHeaders = () => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Accept', 'application/json')

  return myHeaders
}

export function post<T>(url: string, body: object): Promise<T> {
  return fetch(url, {
    method: 'POST',
    headers: setupJsonHeaders(),
    mode: 'cors',
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const isJson = response.headers
        .get('content-type')
        ?.includes('application/json')
      const data = isJson ? await response.json() : {}
      const error = {
        ...data,
        status: response.status,
        statusText: response.statusText,
      }
      setExtra('Resurs trace ID', data.traceId)
      captureMessage(
        `HTTP error! Status: ${response.status} - ${
          isJson ? data.message : response.statusText
        }`,
      )

      return Promise.reject(error)
    }
    return response.json()
  })
}
