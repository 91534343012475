import fi from '../assets/languages/fi.json'
import nb from '../assets/languages/nb.json'
import sv from '../assets/languages/sv.json'
import da from '../assets/languages/da.json'
import { CountryCode } from './countryCode'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const LanguageFiles: { [key: string]: any } = {
  [CountryCode.SE]: sv,
  [CountryCode.NO]: nb,
  [CountryCode.FI]: fi,
  [CountryCode.DK]: da,
}
