import React, { ReactNode } from 'react'
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import LanguageBar from '../../containers/LanguageBar'
import styles from './page.module.scss'

interface Props {
  children: ReactNode
  contentClassName?: string
  showLanguageBar?: boolean
}

const Page = (props: Props) => (
  <div className={styles.page}>
    {props.showLanguageBar && <LanguageBar />}
    <Header />
    <div className={styles.content}>
      <div className={props.contentClassName}>{props.children}</div>
    </div>
    <Footer />
  </div>
)

export default Page
