import React from 'react'
import Page from '../../components/Page'
import Headline from '../../components/Headline'
import { getCountryCode, getText } from '../../utils/languageHandler'
import Card from '../../components/Card'
import CustomerServiceInfo from '../../components/CustomerServiceInfo'
import { CountryCode } from '../../models/countryCode'
import config from '../../config'
import styles from './bs.module.scss'

const BS = () => {
  if (getCountryCode() === CountryCode.DK) {
    window.location.assign(`${config.bsUrl}?country=DK&redirect=false`)
  }

  if (getCountryCode() === CountryCode.NO) {
    window.location.assign(`${config.merchantPortalUrl}?lang=nb`)
  }
  return (
    <Page showLanguageBar>
      <div className={styles.bs}>
        <Headline headline="bs.headline" lead="bs.lead" />
        <p>{getText('bs.info')}</p>

        <Card
          id="buttonMP"
          cardHeadline="bs.card_headline_mp"
          cardInfo="bs.card_info_mp"
          buttonText="bs.card_button_mp"
          href={config.merchantPortalUrl}
          redirect
        />

        <Card
          id="buttonBS"
          cardHeadline="bs.card_headline_bs"
          cardInfo="bs.card_info_bs"
          buttonText="bs.card_button_bs"
          href={`${config.bsUrl}?country=${getCountryCode()}&redirect=false`}
          redirect
        />

        <div className={styles.customerService}>
          <CustomerServiceInfo />
        </div>
      </div>
    </Page>
  )
}
export default BS
