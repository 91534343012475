import React, { useEffect, useState } from 'react'
import { Button, FormFieldGroup, TextInputType } from '@resurs/styx-react'

import Headline from '../../../../components/Headline'
import CustomerServiceInfo from '../../../../components/CustomerServiceInfo'
import FormInfo from '../../../../components/FormInfo'
import { setNestedValue } from '../../../../utils/setNestedValue'
import userServiceApi, {
  UseExistingMerchantPortalAccountRequest,
} from '../../../../api/user-service-api'
import Page from '../../../../components/Page'
import { LanguageCode } from '../../../../models/languageCode'
import { getCountryCode, getText } from '../../../../utils/languageHandler'
import {
  defaultFormFieldValid,
  defaultFormValueState,
  emailFormat,
  InputTypes,
  onFocusElement,
  onValidChangeElement,
  passwordFormat,
} from '../../../../utils/formUtils'
import { ApiError } from '../../../../interfaces/ApiError'
import { handleFormApiErrors } from '../../../../utils/handleFormApiErrors'
import TextInputHelper from '../../../../components/TextInputHelper'
import styles from './formExisting.module.scss'

type Input =
  | InputTypes.OldFinanceUsername
  | InputTypes.OldFinancePassword
  | InputTypes.Username
  | InputTypes.Password

const FormExisting = () => {
  sessionStorage.setItem('flow', 'existing')

  const [valueState, setValueState] = useState({
    [InputTypes.OldFinanceUsername]: defaultFormValueState,
    [InputTypes.OldFinancePassword]: defaultFormValueState,
    [InputTypes.Username]: defaultFormValueState,
    [InputTypes.Password]: defaultFormValueState,
  })

  const [validState, setValidState] = useState({
    [InputTypes.OldFinanceUsername]: defaultFormFieldValid,
    [InputTypes.OldFinancePassword]: defaultFormFieldValid,
    [InputTypes.Username]: defaultFormFieldValid,
    [InputTypes.Password]: defaultFormFieldValid,
  })

  const [error, setError] = useState<ApiError>()

  const createUseExistingMerchantPortalAccountRequest =
    (): UseExistingMerchantPortalAccountRequest => ({
      oldFinanceUsername: valueState[InputTypes.OldFinanceUsername].value,
      oldFinancePassword: valueState[InputTypes.OldFinancePassword].value,
      username: valueState[InputTypes.Username].value,
      password: valueState[InputTypes.Password].value,
      languageCode: LanguageCode[getCountryCode()],
    })

  useEffect(() => {
    handleFormApiErrors(error, (entries) => {
      setValidState({
        ...validState,
        ...entries,
      })
    })
  }, [error])

  const submit = () => {
    userServiceApi
      .useExistingMerchantPortalAccount(
        createUseExistingMerchantPortalAccountRequest(),
      )
      .then(() => {
        window.location.assign(`success`)
      })
      .catch((err) => {
        if (err) {
          setError({ ...err })
        }
      })
  }

  const onChange = (id: string, value: string) => {
    setValueState(setNestedValue(valueState, `${id}.value`, value))
  }

  const onValidChange = (id: string, valid: boolean) => {
    onValidChangeElement(valueState, id as Input, valid, (entry) => {
      setValidState({
        ...validState,
        ...entry,
      })
    })
  }

  const isValid = () => Object.entries(validState).every((el) => el[1].isValid)

  const onFocus = (id: string) => {
    onFocusElement(validState, id as Input, (entries) =>
      setValidState({
        ...validState,
        ...Object.assign({}, ...(entries || [])),
      }),
    )
  }

  const textInputProps = {
    onChange,
    onFocus,
    onValidChange,
    valueState,
    validState,
  }

  return (
    <Page contentClassName={styles.formExisting}>
      <Headline headline="sa.form.headline" lead="sa.form.lead" />
      <p>{getText('sa.form.lead_existing')}</p>

      <FormInfo
        headline="sa.form.info_headline_control"
        info="sa.form.info_control"
      />

      <FormFieldGroup>
        <TextInputHelper
          input={InputTypes.OldFinanceUsername}
          label="sa.form.label.username_finance"
          required="sa.form.label.username_finance.required"
          {...textInputProps}
        />
        <TextInputHelper
          input={InputTypes.OldFinancePassword}
          label="sa.form.label.password_finance"
          type={TextInputType.Password}
          required="sa.form.label.password_finance.required"
          {...textInputProps}
        />
      </FormFieldGroup>

      <FormInfo
        headline="sa.form.info_headline_existing"
        info="sa.form.info_existing"
      />

      <div className={styles.spacingBetweenFormFieldGroups} />

      <FormFieldGroup>
        <TextInputHelper
          input={InputTypes.Username}
          label="sa.form.label.username_email"
          format={emailFormat}
          {...textInputProps}
        />
        <TextInputHelper
          input={InputTypes.Password}
          label="sa.form.label.password"
          format={passwordFormat}
          type={TextInputType.Password}
          {...textInputProps}
        />
      </FormFieldGroup>
      <div className={styles.confirm}>
        <Button
          id="confirm"
          fullWidth
          onClick={submit}
          disabled={!isValid()}
          label={getText('sa.form.button.confirm')}
        />
      </div>
      <CustomerServiceInfo />
    </Page>
  )
}

export default FormExisting
