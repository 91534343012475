/* eslint-disable  @typescript-eslint/no-explicit-any */
export const setNestedValue: any = (
  base: object,
  fieldPath: string,
  value: string,
) => {
  if (!base || typeof base !== 'object') {
    return setNestedValue({}, fieldPath, value)
  }
  if (fieldPath.indexOf('.') === -1) {
    return { ...base, [fieldPath]: value }
  }
  const fields = fieldPath.split('.')
  return {
    ...base,
    [fields[0]]: setNestedValue(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      base[fields[0]],
      fields.splice(1).join('.'),
      value,
    ),
  }
}
